<template>
  <div>
    <!-- 卡片视图区域 -->
    <el-card>
      <!-- 第一行，输入框和添加按钮 -->
      <el-row>
        <!-- 添加按钮 -->
        <el-col :span="5">
          <el-button type="primary" @click="showAddTypeDialog"
            >+ 新增问题类型</el-button
          >
        </el-col>
      </el-row>
      <!-- 第二行，筛选 -->
      <div class="secondrow">
        <!-- 根据商品名查询 -->

        <div class="input">
          使用对象：
          <el-select
            v-model="target"
            clearable
            @clear="getQuestionType"
            placeholder="请选择使用对象"
            @change="getQuestionType"
          >
            <el-option
              v-for="item in targetList"
              :key="item.id"
              :label="item.name"
              :value="item.id"
            >
            </el-option>
          </el-select>
        </div>
      </div>
      <!-- 内容主体 -->
      <el-table :data="questionTypeList" stripe border>
        <!-- 第一列id -->
        <el-table-column label="类型id" width="100" aligin="center" prop="id">
        </el-table-column>
        <!-- 第二列名称 -->
        <el-table-column label="问题类型名称" aligin="center" prop="typeName">
        </el-table-column>
        <!-- 第三列使用对象 -->
        <el-table-column label="使用对象" aligin="center">
          <template slot-scope="scope">
            <div v-if="scope.row.target === 0">消费者C端</div>
            <div v-if="scope.row.target === 1">商户端</div>
          </template>
        </el-table-column>
        <!-- 第七列操作 -->
        <el-table-column label="操作" width="300" aligin="center">
          <template slot-scope="scope">
            <!-- 编辑按钮 -->
            <el-button
              size="mini"
              type="primary"
              @click="showEditTypeDialog(scope.row)"
              >修改</el-button
            >
            <!-- 删除按钮 -->
            <el-button
              size="mini"
              type="danger"
              @click="removeTypeById(scope.row.id)"
              >删除</el-button
            >
            <!-- 问题列表 -->
            <el-button
              size="mini"
              type="primary"
              @click="goQuestionList(scope.row)"
              >问题列表</el-button
            >
            <!-- 添加问题 -->
            <!-- <el-button size="mini" type="primary" @click="addQuestion"
              >添加问题</el-button
            > -->
          </template>
        </el-table-column>
      </el-table>
    </el-card>
    <!-- 新增对话框 -->
    <el-dialog
      :title="title"
      :visible.sync="addQuestionTypeDialogVisible"
      width="600px"
      @close="clearAddInfo"
    >
      <el-form
        ref="commonQuestionTypeFormRef"
        :rules="commonQuestionTypeFormRules"
        :model="commonQuestionTypeForm"
        label-width="80px"
      >
        <el-form-item label="使用对象" prop="target">
          <el-select
            v-model="commonQuestionTypeForm.target"
            placeholder="请选择使用对象"
          >
            <el-option
              v-for="item in targetList"
              :key="item.id"
              :label="item.name"
              :value="item.id"
            >
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="类型名称" prop="typeName">
          <el-input
            v-model="commonQuestionTypeForm.typeName"
            placeholder="请输入类型名称"
          ></el-input>
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button @click="addQuestionTypeDialogVisible = false"
          >取 消</el-button
        >
        <el-button type="primary" @click="submit">确 定</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
export default {
  data() {
    return {
      // target
      target: '',
      // 问题分类列表
      questionTypeList: [],
      // 标题
      title: '',
      // 新增还是修改
      submitType: '',
      // 修改的id
      typeId: '',
      // 对话框
      addQuestionTypeDialogVisible: false,
      // 新增类型入参
      commonQuestionTypeForm: {
        target: undefined, // 使用对象0-消费者C端 1-商户端
        typeName: ''
      },
      // 选择项
      targetList: [
        {
          id: 0,
          name: '消费者C端'
        },
        {
          id: 1,
          name: '商户端'
        }
      ],
      // 校验
      commonQuestionTypeFormRules: {
        target: [
          { required: true, message: '请选择使用对象', trigger: 'blur' }
        ],
        typeName: [
          { required: true, message: '请输入问题类型名称', trigger: 'blur' }
        ]
      }
    }
  },
  created() {
    // 请求分类
    this.getQuestionType()
  },
  methods: {
    // 定义请求分类的方法
    async getQuestionType() {
      const { data: res } = await this.$http.get(
        `commonQuestionType/commonQuestionTypeList?target=${this.target}`
      )
      if (res.code !== 0) {
        return this.$message.error('获取问题类型失败')
      }
      // 赋值
      this.questionTypeList = res.data
    },
    // 点击新增按钮
    showAddTypeDialog() {
      this.title = '新增问题类型'
      this.submitType = 'add'
      this.addQuestionTypeDialogVisible = true
    },
    // 提交新增和修改请求
    async submit() {
      this.$refs.commonQuestionTypeFormRef.validate(async valid => {
        // console.log(valid)
        if (!valid) return
        // 可以发起网络请求
        if (this.submitType === 'add') {
          const { data: res } = await this.$http.post(
            'commonQuestionType/',
            this.commonQuestionTypeForm
          )
          if (res.code !== 0) {
            return this.$message.error('添加问题类型失败')
          }
          this.$message.success('添加问题类型成功')
          this.getQuestionType()
          this.addQuestionTypeDialogVisible = false
        } else {
          // 赋值成功后提交
          const { data: res } = await this.$http.put(
            `commonQuestionType/${this.typeId}`,
            this.commonQuestionTypeForm
          )
          if (res.code !== 0) {
            return this.$message.error('修改问题类型失败')
          }
          this.$message.success('修改问题类型成功')
          this.getQuestionType()
          this.addQuestionTypeDialogVisible = false
        }
      })
    },
    // 关闭对话框
    clearAddInfo() {
      this.commonQuestionTypeForm = {}
    },
    // 编辑
    showEditTypeDialog(row) {
      this.commonQuestionTypeForm = row
      this.addQuestionTypeDialogVisible = true
      this.title = '修改问题类型'
      this.submitType = 'edit'
      // 赋值修改的id
      this.typeId = row.id
    },
    // 删除
    async removeTypeById(id) {
      // 弹框提醒
      const confirmResult = await this.$confirm(
        '此操作将永久删除该问题类型，是否继续？',
        '提示',
        {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning'
        }
      ).catch(err => err)
      if (confirmResult !== 'confirm') {
        return this.$message.info('已取消删除')
      }
      const { data: res } = await this.$http.delete(`commonQuestionType/${id}`)
      if (res.code !== 0) {
        return this.$message.error('删除问题类型失败')
      }
      this.$message.success('删除问题类型成功')
      this.getQuestionType()
    },
    // 跳转问题列表
    goQuestionList(row) {
      this.$router.push({
        path: 'question-list',
        query: { id: row.id, target: row.target }
      })
    }
  }
}
</script>

<style lang="less" scoped>
.secondrow {
  margin: 20px 0;
}
</style>
